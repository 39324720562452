// We import our styles here, webpack will do the rest
import '../scss/app.scss'
import '../scss/tailwind_utilities.scss'
import '../scss/utilities.scss'

// lazy sizes for lazy loading images
import Lazysizes from 'lazysizes'
import 'picturefill'

// Our own helpers
import './helpers/objectfitFallback'
import reframe from './helpers/iframe'

// Window onload (after all dom and images are loaded completely)
window.onload = function() {
  // responsive iframes
  reframe(document.querySelectorAll('.wysiwyg iframe')) // Pass a live node list

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading')

  // Responsive tables
  // wrap tables in wysiwyg in a div for styling
  const tables = document.querySelectorAll('.wysiwyg table')
  if (tables.length) {
    if (!('length' in tables)) tables = [tables]
    for (let i = 0; i < tables.length; i += 1) {
      const table = tables[i]

      const div = document.createElement('div')
      div.className = 'table-container'

      table.parentNode.insertBefore(div, table)
      table.parentNode.removeChild(table)
      div.appendChild(table)
    }
  }
}
